











import { computed, defineComponent } from '@vue/composition-api';
import { DetectionResult } from '@/model/store';
import { useSetupWizardStore } from '@/stores/setup-wizard';
import TariffSelection from '@/components/wizard/steps/projects/TariffSelection.vue';
import AppWizardTitle from '@/components/wizard/forms/title/WizardTitle.vue';

export default defineComponent({
  name: 'SetupChooseFramework',
  components: { TariffSelection, AppWizardTitle },
  setup() {
    const setupWizardStore = useSetupWizardStore();
    const detectionResults = computed<DetectionResult[]>(() => setupWizardStore.detectionResults || []);

    const setupFromIonosYaml = computed<boolean>(() => {
      return !!detectionResults.value.filter(
        // TODO: Replace hardcoded 'ionos.yaml' with variable or enum
        (detectionResult: DetectionResult) => detectionResult.selectedProjectType === 'ionos.yaml'
      ).length;
    });

    return {
      setupFromIonosYaml,
    };
  },
});
