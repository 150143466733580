



































import { defineComponent, ref, computed, onBeforeMount } from '@vue/composition-api';
import { prettyBytes } from '@/utils';
import { useMasterDataStore } from '@/stores/master-data';
import { useAccountInfoStore } from '@/stores/account-info';
import { useSetupWizardStore } from '@/stores/setup-wizard';
import { DetectionResult, FrameworkPanel } from '@/model/store';
import { isPhpProjectByType, isStarterProjectByType } from '@/utils/projects';
import i18n from '@/plugins/i18n';
import { FrameworkIdentifier } from 'ionos-space-api-v4';
import { FRAMEWORK_CUSTOM_ID } from '@/utils/const';
import AppProjectTypeFeatures from '@/components/shared/projectType/Features.vue';
import AppProjectTypeHeader from '@/components/shared/projectType/Header.vue';

export default defineComponent({
  name: 'TariffSelection',
  components: { AppProjectTypeHeader, AppProjectTypeFeatures },
  props: {},
  setup() {
    const masterDataStore = useMasterDataStore();
    const accountInfoStore = useAccountInfoStore();
    const setupWizardStore = useSetupWizardStore();

    const radioMessage = (framework: FrameworkIdentifier): string => {
      return framework.id.trim() !== FRAMEWORK_CUSTOM_ID
        ? i18n.t('tariffUpgrade.item.prefill', { framework: framework.name })
        : i18n.t('tariffUpgrade.item.default', { framework: framework.name });
    };

    const detectionResults = computed<DetectionResult[]>(() => setupWizardStore.detectionResults || []);

    const allowedTypes = masterDataStore.getPackageWithRequiredFeatures([]);

    onBeforeMount(() => {
      const result = detectionResults.value.find((detection) => detection.requiredFeatures.length !== 0);
      if (result) {
        setupWizardStore.selectProjectTypePanel(FrameworkPanel.php);
      } else {
        setupWizardStore.selectProjectTypePanel(FrameworkPanel.starter);
      }
    });

    const selectedRadio = ref<number>(setupWizardStore.selectedDetectionIndex);

    const selectDetectionResult = (index: number) => {
      setupWizardStore.selectDetectionResult(index);
    };

    const panel = computed<number>({
      get() {
        return setupWizardStore.selectedProjectTypePanel;
      },
      set(value) {
        setupWizardStore.selectProjectTypePanel(value);
        selectDetectionResult(selectedRadio.value);
      },
    });

    const upgradeTariff = (name: string) => {
      accountInfoStore.upgradeTariff(name);
    };

    return {
      panel,
      allowedTypes,
      detectionResults,
      selectedRadio,
      upgradeTariff,
      selectDetectionResult,
      radioMessage,

      isPhpProjectByType,
      isStarterProjectByType,
      prettyBytes,

      accountInfoStore,
    };
  },
});
